import DesktopFloatManager from './desktopFloatManager';
/**
 * Updating the User Experience of the floating player.
 *
 * @param {object} player The JW Player object.
 * @param {object} config Arguments passed to this plugin.
 */
function jwFloatingPlayerDesktop(player, config) {
    if (config === void 0) { config = {}; }
    var manager = new DesktopFloatManager(player, config);
    player.on('ready', manager.handlePlayerReady);
}
var registerPlugin = window.jwplayerPluginJsonp || window.jwplayer().registerPlugin;
// Register the plugin.
registerPlugin('jw-player-floatingPlayerDesktop.bundle.min', '8.0', jwFloatingPlayerDesktop);
